var nav = {
  top: [
    {
      name: "Home",
      url: "/admin/home",
      icon: "Home",
    },
    {
      name: "Facilities",
      url: "/admin/facilities",
      icon: "Package",
    },
    {
      name: "Billing System",
      url: "/admin/billingsystem",
      icon: "Airplay",
    },
    {
      name: "Auditlogs",
      url: "/admin/auditlogs",
      icon: "Layers",
    },
  ],
};
export default nav;