/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardHeader,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import { faRedo, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";

import { AlertComponent, DatatableNoData } from "../../../../components";
import { axios } from "../../../../config";
import { browserStorageHelper, commonHelpers } from "../../../../helpers";
import { datatableConfig } from "../../../../config";

class AuditLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      logData: "",
      records: [],
      recordsCount: 0,
      sortBy: "auditlog_id",
      sortType: "DESC",
      datatableMsg: "No data found for audit log.",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
      perPage: datatableConfig.PER_PAGE_ITEMS,
      perPageOptions: datatableConfig.PER_PAGE_OPTIONS_ARR,
      searchText: "",
      enableSearch: false,
      /**
       * Current page in datatable pagination. default is 1.
       */
      currentPage: 1,
    };
    this.msgBlank = commonHelpers.setMsg("", "");
    this.msg = commonHelpers.setMsg("Fetching data...", "");
    this.handleClearSearch = this.handleClearSearch.bind(this);

    /**
     * Columns for Datatable
     */
    this.columns = [
      {
        selector: (row) => row.auditlog_id,
        sortField: "auditlog_id",
        name: "Log Id",
        className: "auditlog_id",
        center: true,
        sortable: true,
        width: "100px",
        compact: true,
      },
      {
        selector: (row) => row.auditlog_request_url,
        sortField: "auditlog_request_url",
        name: "URL",
        className: "auditlog_request_url",
        left: true,
        sortable: true,
        width: "auto",
        wrap: true,
        compact: true,
      },
      {
        selector: (row) => row.auditlog_request_method,
        sortField: "auditlog_request_method",
        name: "Method",
        className: "auditlog_request_method",
        center: true,
        sortable: true,
        width: "100px",
        compact: true,
      },
      {
        selector: (row) => row.auditlog_ip_address,
        sortField: "auditlog_ip_address",
        name: "IP Address",
        className: "auditlog_ip_address",
        center: true,
        sortable: true,
        width: "140px",
        compact: true,
        wrap: true,
      },
      {
        selector: (row) => row.auditlog_event_datetime,
        sortField: "auditlog_event_datetime",
        name: "Time",
        className: "auditlog_event_datetime",
        center: true,
        sortable: true,
        width: "200px",
        compact: true,
        wrap: true,
      },
      {
        selector: (row) => row.auditlog_authorized,
        sortField: "auditlog_authorized",
        name: "Authorized",
        className: "auditlog_authorized",
        sortable: true,
        center: true,
        width: "100px",
        compact: true,
      },
      {
        selector: (row) => row.auditlog_response_code,
        sortField: "auditlog_response_code",
        name: "Response Code",
        className: "auditlog_response_code",
        sortable: true,
        center: true,
        width: "100px",
        compact: true,
      },
      {
        selector: (row) => row.action,
        name: "Action",
        className: "action",
        width: "100px",
        center: true,
        sortable: false,
        ignoreRowClick: true,
        compact: true,
        cell: (record) => {
          return (
            <Button
              className="action-btns"
              title="Click to view"
              onClick={() => this.getAuditLogDetails(record.auditlog_id)}
            >
              <FontAwesomeIcon icon={faEye} className="fa-fw" />
            </Button>
          );
        },
      },
    ];
    /**
     * Configuration used for Datatable
     */
    this.config = commonHelpers.getDatatableConfig("auditlog_id", "desc");
  }
/**
   * Handlers for Sorting
   */
 handleSort = async (column, direction) => {
  this.setState({
    sortBy:column.sortField,
    sortType: direction.toUpperCase(),
  });
  const { page, perPage, searchText } = this.state;
  this.getAuditLogList(
    page,
    perPage,
    searchText,
    false,
    column.sortField,
    direction.toUpperCase()
  );
};
  /**
   * Reuseable function to get audit log list
   *
   * @param {number} page Page number
   * @param {number} perPage Rows per page
   * @param {string} searchText Search text
   */
  async getAuditLogList(
    page = 1,
    perPage = this.state.perPage,
    searchText = "",
    paginationReset,
    sortBy,
    sortType
  ) {
    const self = this;
    self.setState({
      msg: self.msg,
    });
    const token = browserStorageHelper.getItem("token");
    const userId = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    sortBy = sortBy || this.state.sortBy;
    sortType = sortType || this.state.sortType;
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/auditlog?user_id=${userId}&search=${searchText}&page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_type=${sortType}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        if (paginationReset) {
          commonHelpers.resetDatatablePagination();
        }
        self.setState({
          records: res.data.result.rows,
          recordsCount: res.data.result.count,
          msg: self.msgBlank,
          currentPage: page,
          perPage,
        });
      })
      .catch((err) => {
        const msgErr = commonHelpers.errMessage(err);
        self.setState({
          records: [],
          recordsCount: 0,
          msg: msgErr,
        });
      });
  }

  /**
   * Get initial list of audit log on component mount
   *
   * @param {number} page Current page. Default is 1
   */
  async componentDidMount(page = 1) {
    const self = this;
    const { perPage } = self.state;
    self.getAuditLogList(page, perPage, self.state.searchText);
  }

  /**
   * Handler for page change event
   */
  handlePageChange = async (page) => {
    const self = this;
    const { perPage } = self.state;
    self.getAuditLogList(page, perPage, self.state.searchText);
  };

  /**
   * Handler rows for page change event
   */
  handlePerRowsChange = async (perPage, page) => {
    const self = this;
    await self.getAuditLogList(page, perPage, self.state.searchText, true);
  };

  /**
   * Handler for search field change event
   */
  handleSearchTextChange = async (e) => {
    const self = this;
    var searchText = e.target.value;
    self.setState({
      msg: self.msgBlank,
      searchText: searchText,
    });
    self.setState({
      enableSearch: true,
    });
    var perPage = self.state.perPage;
    var page = 1;
    var paginationReset = true;
    await self.getAuditLogList(page, perPage, searchText, paginationReset);
  };

  /**
   * Handler for search field clear button event
   */
  handleClearSearch() {
    this.setState({ searchText: "" });
    if (this.state.enableSearch === true) {
      window.location.reload();
    }
  }

  /**
   * Get audit log details by id
   *
   * @param {number} id Audit log id
   */
  getAuditLogDetails = async (id) => {
    const self = this;
    self.setState({
      msg: self.msg,
    });
    const token = browserStorageHelper.getItem("token");
    const uid = browserStorageHelper.getItem("uid");
    const auditLogId = id;
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/auditlog/${auditLogId}?user_id=${uid}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        self.setState({
          logData: res.data.result.logData,
          msg: self.msgBlank,
          modal: true,
        });
      })
      .catch((err) => {
        const msgErr = commonHelpers.errMessage(err);
        self.setState({
          msg: msgErr,
        });
      });
  };

  /**
   * Handler for audit log details model change
   */
  handleToggleChange = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      striped,
      highlightOnHover,
      responsive,
      noHeader,
      className,
      customTheme,
    } = this.config;
    const {
      msg,
      datatableMsg,
      perPageOptions,
      perPage,
      recordsCount,
      records,
      enableSearch,
      searchText,
      modal,
    } = this.state;

    const {
      auditlog_id,
      auditlog_request_url,
      auditlog_request_method,
      auditlog_request_query,
      auditlog_request_payload,
      auditlog_response_payload,
      auditlog_ip_address,
      auditlog_browser_useragent,
      auditlog_event_datetime,
      auditlog_authorized,
      auditlog_response_code,
    } = this.state.logData;
    return (
      <div className="container-fluid mt-3">
        <Row className="py-3">
          <Col md="12">
            <Card>
              <CardHeader className="card-header-tab z-index-6">
                <Row>
                  <Col size="12" lg="9" className="mb-2 font-weight-bold">
                    Logs
                  </Col>
                  <Col size="12" lg="3" className="mb-2">
                    <Link to="/admin/home">
                      <Button
                        className="mb-2 mr-2 btn-square btn-gradient-success backBtn"
                        style={{ float: "right" }}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faRedo} className="fa-fw" /> Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col size="12" sm="6" lg="8" className="text-left">
                    {" "}
                    <AlertComponent msg={msg} />
                  </Col>
                  <Col size="6" lg="4" className="mb-2">
                    <Form inline>
                      <FormGroup style={{ display: "flex" }}>
                        <Label for="searchText">
                          <FontAwesomeIcon icon={faSearch} className="fa-fw" />
                        </Label>
                        <input
                          className="form-control form-control-sm mr-2"
                          type="text"
                          name="searchText"
                          placeholder="Search For:"
                          aria-label="Search For:"
                          value={searchText}
                          onChange={this.handleSearchTextChange}
                          autoComplete="false"
                        />
                      </FormGroup>
                      {enableSearch === true ? (
                        <Button
                          className="mb-2 mr-2 btn-square btn btn-danger btn-sm"
                          onClick={this.handleClearSearch}
                          title="Click to reset search filter if applied."
                        >
                          <FontAwesomeIcon icon={faRedo} className="fa-fw" />
                        </Button>
                      ) : (
                        ""
                      )}
                    </Form>
                    {enableSearch === true ? (
                      <p className="text-success help-block">
                        <small>
                          <i className="fa fa-info-circle fa-fw" /> Search
                          filter applied.
                        </small>
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <DataTable
                  noHeader={noHeader}
                  columns={this.columns}
                  data={records}
                  striped={striped}
                  highlightOnHover={highlightOnHover}
                  responsive={responsive}
                  noDataComponents={
                    <DatatableNoData
                      msg={datatableMsg}
                      columns={this.columns}
                    />
                  }
                  onSort={(column, sortDirection) => {
                    this.handleSort(column, sortDirection);
                  }}
                  pagination
                  paginationServer
                  paginationTotalRows={recordsCount}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={perPageOptions}
                  className={className}
                  customStyles={customTheme}
                />

                <Modal className="modal-dialog modal-xl"
                  isOpen={modal}
                  toggle={this.handleToggleChange}
                  size="lg"
                >
                  <ModalHeader toggle={this.handleToggleChange}>
                    Log Details:
                  </ModalHeader>
                  <ModalBody>
                    <Table className="table table-striped table-bordered table-hover table-condensed auditlog-details-table">
                      <tbody>
                        <tr>
                          <th className="text-warpped">Log Id</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">{auditlog_id}</th>
                        </tr>
                        <tr>
                          <th className="text-warpped">URL</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_request_url}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Method</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_request_method}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Query String</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_request_query}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Payload</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_request_payload}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Response</th>
                          <th className="text-warpped-center">:</th>
                          <th
                            className="overflow-wrap-hack"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {auditlog_response_payload != "" &&
                            auditlog_response_payload != null
                              ? auditlog_response_payload
                              : "-"}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">IP Address</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_ip_address}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">User Agent</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_browser_useragent}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Time</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_event_datetime}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Authorized</th>
                          <th className="text-warpped-center">:</th>
                          <th className="overflow-wrap-hack">
                            {auditlog_authorized}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-warpped">Response Code</th>
                          <th className="text-warpped-center">:</th>
                          <th style={{ width: "200px" }}>
                            {auditlog_response_code > 0 &&
                            auditlog_response_code != null
                              ? auditlog_response_code
                              : "-"}
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AuditLog;
