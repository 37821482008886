import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

function DatatableNoData({ msg, columns }) {
  const columnCount = columns.length;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col size="12" lg="12">
            <table className="table table-striped table-bordered table-hover table-sm">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={columnCount}>
                    <p className="text-center text-bold">{msg}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default DatatableNoData;
