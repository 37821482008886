import React, { PureComponent } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardHeader,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faRedo,
  faPlusCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

import { axios } from "../../../../config";
import { browserStorageHelper, commonHelpers,encryptionHelper } from "../../../../helpers";
import { AlertComponent } from "../../../../components";

/* eslint-disable-next-line react/jsx-props-no-spreading */
function withParams(Component) {
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

class EditFacility extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      facilityId: "",
      facilityName: "",
      facilityAppId: "",
      facilityEhrType:"",
      ownerName: "",
      activeStatus: 1,
      redirectUrl: "/admin/facilities",
      optionsFacilities: [
        {
          value: "Epic",
          label: "Epic",
        },
        {
          value: "Cerner",
          label: "Cerner",
        },
      ],
      selectedOptionFacilities: "",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  componentDidMount() {
    const self = this;
    const { facilityId } = this.props.params;
    if (typeof facilityId === "undefined") {
      const redirect = true;
      const redirectUrl = self.state.redirectUrl;
      self.setState({ redirect, redirectUrl });
    }

    self.setState({
      facilityId,
      msg: commonHelpers.setMsg("Fetching data...", "", "", ""),
    });

    const token = browserStorageHelper.getItem("token");
    const uid = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}?user_id=${uid}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        const facilityDetails = res.data.result.facility_details;
        this.setState({
          msg: commonHelpers.setMsg("", "", "", ""),
          facilityName: facilityDetails.facility_name,
          facilityAppId: facilityDetails.facility_app_id,
          facilityEhrType:facilityDetails.facility_ehr_type,
          ownerName: facilityDetails.facility_owner_name,
          activeStatus: facilityDetails.facility_status,
          selectedOptionFacilities: {
            value: facilityDetails.facility_ehr_type,
            label: facilityDetails.facility_ehr_type,
          },
        });
      })
      .catch((err) => {
        self.setState({
          msg: commonHelpers.errMessage(err),
        });
        window.location.href = `/admin/facilities?error=${encryptionHelper.encrypt(self.state.msg.msgError,"key")}`;
      });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const self = this;
    if (this.validator.allValid()) {
       self.setState({
        msg: commonHelpers.setMsg("Updating facility...", "", "", ""),
      });
      const token = browserStorageHelper.getItem("token");
      const userId = browserStorageHelper.getItem("uid");
      const requestToken = commonHelpers.generateRequestToken();
      const {
        facilityName,
        facilityAppId,
        ownerName,
        facilityId,
        activeStatus,
        facilityEhrType,
      } = this.state;
      const postData = {
        user_id: userId,
        facility_name: facilityName.trim(),
        facility_app_id: facilityAppId.trim(),
        facility_owner_name: ownerName,
        facility_status: activeStatus,
        facility_ehr_type: facilityEhrType,
        request_token: requestToken
      };
      const axiosOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}`,
          postData,
          axiosOptions
        )
        .then(async () => {
           self.setState({
            msg: commonHelpers.setMsg(
              "",
              "",
              "Facility updated successfully.",
              ""
            ),
          });
          const redirect = true;
          const redirectUrl = self.state.redirectUrl;
           self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
           self.setState({
            msg: commonHelpers.errMessage(err),
          });
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  EhrChangeHandler = (selectedOptionFacilities) => {
    const facilityEhrType = selectedOptionFacilities.value;
    this.setState({ selectedOptionFacilities, facilityEhrType });
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const { facilityName, facilityAppId, ownerName, msg,facilityEhrType } = this.state;
    return (
      <div>
        {this.renderRedirect()}
        <div className="container-fluid mt-3">
          <Row className="py-3">
            <Col md="12">
              <Card>
                <CardHeader className="card-header-tab z-index-6">
                  <Row>
                    <Col size="12" lg="9" className="mb-2 font-weight-bold">
                      Edit Facility
                    </Col>
                    <Col size="12" lg="3" className="mb-2">
                      <Link to="/admin/facilities">
                        <Button
                          className="mb-2 mr-2 btn-square btn-gradient-success backBtn"
                          style={{ float: "right" }}
                        >
                          <FontAwesomeIcon icon={faRedo} className="fa-fw" />{" "}
                          Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col size="12" lg="12" className="mb-2">
                      <Form
                        name="frmAddFacility"
                        className="frmAddFacility needs-validation"
                        id="frmAddFacility"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        <FormGroup row>
                          <Label for="facilityName" sm={2}>
                            Name <span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <Input
                              value={facilityName}
                              name="facilityName"
                              onChange={this.changeHandler}
                              type="text"
                              id="facilityName"
                              placeholder="Facility Name"
                              required
                            />
                            {this.validator.message(
                              "facilityName",
                              facilityName,
                              "required"
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="facilityAppId" sm={2}>
                            App Id <span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <Input
                              value={facilityAppId}
                              name="facilityAppId"
                              onChange={this.changeHandler}
                              type="text"
                              id="facilityAppId"
                              placeholder="Facility App Id"
                              required
                            />
                            {this.validator.message(
                              "facilityAppId",
                              facilityAppId,
                              "required"
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="facilityEhrType" sm={2}>
                            Facility Ehr Type<span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <Select
                              id="facilityEhrType"
                              name="facilityEhrType"
                              value={this.state.selectedOptionFacilities}
                              onChange={this.EhrChangeHandler}
                              options={this.state.optionsFacilities}
                              required={true}
                            />
                            {this.validator.message(
                              "facilityEhrType",
                              facilityEhrType,
                              "required"
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="ownerName" sm={2}>
                            Owner Name <span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <Input
                              value={ownerName}
                              name="ownerName"
                              onChange={this.changeHandler}
                              type="text"
                              id="ownerName"
                              placeholder="Facility Owner Name"
                              required
                            />
                            {this.validator.message(
                              "ownerName",
                              ownerName,
                              "required"
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup check row>
                          <Col sm={{ size: 10, offset: 2 }}>
                            <Button className="mb-2 mr-2 btn-square btn-gradient-success btn btn-">
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="fa-fw"
                              />{" "}
                              Submit
                            </Button>
                          </Col>
                        </FormGroup>
                        <AlertComponent msg={msg} />
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withParams(EditFacility);
