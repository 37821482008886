import React, { PureComponent } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Navigate } from "react-router-dom";

import { browserStorageHelper, commonHelpers } from "../../../../helpers";
import { axios } from "../../../../config";

const styles = {
  borderRadius: "10px",
  backgroundColor: "#8f93b2",
  backgroundImage: "linear-gradient(315deg, #9e8fb2 0%, #162c50ad 74%)",
  color: "white",
};

class Dashboard extends PureComponent {
  constructor() {
    super();
    this.state = {
      facilitiesCount: 0,
      billingSystemCount: 0,
      redirectToFacilities: "/admin/facilities",
      redirectToBilling: "/admin/billingsystem",
    };
    this.handleRedirect = this.handleRedirect.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  async componentDidMount() {
    const self = this;
    self.getList();
  }

  async getList() {
    const self = this;
    const token = browserStorageHelper.getItem("token");
    const userId = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/dashboard?user_id=${userId}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        self.setState({
          facilitiesCount: res.data.result.Facilities,
          billingSystemCount: res.data.result.BillingSystem,
        });
      })
      .catch((err) => {
        const msgErr = commonHelpers.errMessage(err);
        self.setState({
          facilitiesCount: 0,
          msg: msgErr,
        });
      });
  }

  handleRedirect = (key) => {
    let redirect = true;
    let redirectUrl;
    if (key === "facilities") {
      redirectUrl = this.state.redirectToFacilities;
    }
    if (key === "billingsystem") {
      redirectUrl = this.state.redirectToBilling;
    }
    this.setState({ redirect, redirectUrl });
  };
  
  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const { facilitiesCount, billingSystemCount } = this.state;

    return (
      <div>
        {this.renderRedirect()}
        <Row>
          <Col md={4}>
            <Card
              className="cards"
              style={styles}
              onClick={() => this.handleRedirect("facilities")}
            >
              <CardBody className="display-flex">
                <div className="m-l">
                  <h2 className="h4">Facilities</h2>
                  <div>{facilitiesCount}</div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className="cards"
              style={styles}
              onClick={() => this.handleRedirect("billingsystem")}
            >
              <CardBody className="display-flex">
                <div className="m-l">
                  <h2 className="h4">Billing System</h2>
                  <div>{billingSystemCount}</div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
