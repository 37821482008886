/* eslint-disable */
import React, { PureComponent } from "react";

import { appConfigs } from "../../../config";

class Home extends PureComponent {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="App">
        <div className="page-wrap">
          <div className="text-center">
            {" "}
            EHR Integration Middleware - {appConfigs.COMPANY_NAME}
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
