/* eslint-disable */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Routes
import Dashboard from "./views/pages/admin/Layouts/adminLayout";
import Home from "./views/pages/Home";
import Login from "./views/pages/admin/Login";

//styles
import "./styles/scss/styles.scss";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}
