import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, id },
    } = this;

    return (
      <div key={id}
        style={{
          border: "1px solid #6c757d38",
          padding: "12px 10px",
          borderRadius: "0.25rem",
        }}
      >
        <div onClick={onClick} style={{ cursor: "pointer" }}>
          <div className="mb-2 font-weight-bold">
            {!isOpen && (
              <i
                className="fa fa-plus-circle"
                aria-hidden="true"
                style={{ fontSize: "24px", color: "#162c50" }}
              ></i>
            )}
            {isOpen && (
              <i
                className="fa fa-minus-circle"
                aria-hidden="true"
                style={{ fontSize: "24px", color: "#162c50" }}
              ></i>
            )}
            &nbsp;&nbsp;
            {label}
          </div>
        </div>
        {isOpen && (
          <div
            
            style={{
              marginTop: 10,
              padding: "10px 30px",
            }}
           
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
