/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faPlusCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../../config";
import {
  browserStorageHelper,
  commonHelpers,
  encryptionHelper,
} from "../../../../../helpers";
import { AlertComponent } from "../../../../../components";

/* eslint-disable-next-line react/jsx-props-no-spreading */
function withParams(Component) {
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

class EpicFacilityCredentials extends PureComponent {
  constructor() {
    super();
    this.state = {
      facilityId: "",
      ehrType: "",
      epicClientId: "",
      epicClientSecret: "",
      epicAuthorizeEndpoint: "",
      epicTokenEndpoint: "",
      epicFhirEndpoint: "",
      epicAudienceEndpoint: "",
      epicMetadataEndpoint: "",
      responseType: "",
      isshowMsg: false,
      redirectUrl: "/admin/facilities",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
      epicMsg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  componentDidMount = async () => {
    const self = this;
    if (self.state.epicMetadataEndpoint != "") {
      self.setState({
        isshowMsg: false,
      });
    }

    const { facilityId } = this.props.params;
    if (typeof facilityId === "undefined") {
      const redirect = true;
      const redirectUrl = self.state.redirectUrl;
      self.setState({ redirect, redirectUrl });
    }
    self.setState({
      facilityId,
      msg: commonHelpers.setMsg("Fetching data...", "", "", ""),
    });

    const token = browserStorageHelper.getItem("token");
    const uid = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/credentials?user_id=${uid}&request_token=${requestToken}`,
          axiosOptions
        )
        .then((res) => {
          let facilityCredentials = res.data.result.facilityCredentials;
          self.setState({
            msg: commonHelpers.setMsg("", "", "", ""),
          });
          if (facilityCredentials !== null) {
            self.setState({
              epicClientId:
                facilityCredentials.epic_client_id === null
                  ? ""
                  : facilityCredentials.epic_client_id,
              epicClientSecret:
                facilityCredentials.epic_client_secret === null
                  ? ""
                  : facilityCredentials.epic_client_secret,
              epicAuthorizeEndpoint:
                facilityCredentials.epic_authorize_endpoint === null
                  ? ""
                  : facilityCredentials.epic_authorize_endpoint,
              epicTokenEndpoint:
                facilityCredentials.epic_token_endpoint === null
                  ? ""
                  : facilityCredentials.epic_token_endpoint,
              epicFhirEndpoint:
                facilityCredentials.epic_fhir_endpoint === null
                  ? ""
                  : facilityCredentials.epic_fhir_endpoint,
              epicAudienceEndpoint:
                facilityCredentials.epic_audience_endpoint === null
                  ? ""
                  : facilityCredentials.epic_audience_endpoint,
              epicMetadataEndpoint:
                facilityCredentials.epic_metadata_endpoint === null
                  ? ""
                  : facilityCredentials.epic_metadata_endpoint,
            });
          }
          self.setState({
            msg: commonHelpers.setMsg("", "", "", ""),
          });
        })
        .catch((err) => {
          self.setState({
            msg: commonHelpers.errMessage(err),
          });
          window.location.href = `/admin/facilities?error=${encryptionHelper.encrypt(
            self.state.msg.msgError
          )}`;
        });
  
  };

  handleEpicSubmit = async (event) => {
    event.preventDefault();
    const self = this;
    self.setState({
      isshowMsg: false,
    });
    if (this.validator.allValid()) {
      self.setState({
        epicMsg: commonHelpers.setMsg(
          "Updating epic facility credentials...",
          "",
          "",
          ""
        ),
      });
      const token = browserStorageHelper.getItem("token");
      const userId = browserStorageHelper.getItem("uid");
      const requestToken = commonHelpers.generateRequestToken();
      const {
        facilityId,
        epicClientId,
        epicClientSecret,
        epicAuthorizeEndpoint,
        epicTokenEndpoint,
        epicFhirEndpoint,
        epicAudienceEndpoint,
        epicMetadataEndpoint,
      } = this.state;
      const postData = {
        user_id: userId,
        epic_client_id: epicClientId,
        epic_client_secret: epicClientSecret,
        epic_authorize_endpoint: epicAuthorizeEndpoint,
        epic_token_endpoint: epicTokenEndpoint,
        epic_fhir_endpoint: epicFhirEndpoint,
        epic_audience_endpoint: epicAudienceEndpoint,
        epic_metadata_endpoint: epicMetadataEndpoint,
        request_token: requestToken,
      };
      const axiosOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/epic/credentials`,
          postData,
          axiosOptions
        )
        .then((res) => {
          self.setState({
            epicMsg: commonHelpers.setMsg(
              "",
              "",
              "Epic Facility credentials updated successfully.",
              ""
            ),
          });
          const redirect = true;
          const redirectUrl = self.state.redirectUrl;
          self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
          self.setState({
            epicMsg: commonHelpers.errMessage(err),
          });
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  handlefetchDetails = async (event) => {
    const self = this;

    const token = browserStorageHelper.getItem("token");
    const userId = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { epicMetadataEndpoint, epicClientId, responseType } = self.state;
    self.setState({
      isshowMsg: false,
    });
    var cilentId, metaDataEndpoint;
    if (event === "Epic") {
      if (epicMetadataEndpoint === "") {
        return self.setState({
          isshowMsg: true,
        });
      }
      self.setState({
        epicMsg: commonHelpers.setMsg(
          "Fetching FHIR Epic Server Endpoints...",
          "",
          "",
          ""
        ),
      });
      cilentId = epicClientId;
      metaDataEndpoint = epicMetadataEndpoint;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/metadata?user_id=${userId}&metadata_endpoint=${metaDataEndpoint}&response_type=${responseType}&client_id=${cilentId}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        const ehr_authorize_endpoint = res.data.result.authorizeEndpoint;
        const ehr_token_endpoint = res.data.result.tokenEndpoint;
        if (ehr_authorize_endpoint === null && ehr_token_endpoint === null) {
          if (event === "Epic") {
            return self.setState({
              epicMsg: commonHelpers.setMsg(
                "",
                "Error: Unable to fetch epic metadata details.",
                "",
                ""
              ),
            });
          }
        }
        if (event === "Epic") {
          self.setState({
            epicAuthorizeEndpoint: ehr_authorize_endpoint,
            epicTokenEndpoint: ehr_token_endpoint,
          });
          self.setState({
            epicMsg: commonHelpers.setMsg(
              "",
              "",
              "Epic metadata details fetched successfully.",
              ""
            ),
          });
        }
      })
      .catch((err) => {
        if (event === "Epic") {
          self.setState({
            epicMsg: commonHelpers.errMessage(err),
          });

          self.setState({
            epicAuthorizeEndpoint: "",
            epicTokenEndpoint: "",
          });
        }
      });
  };

  render() {
    const {
      epicClientId,
      epicClientSecret,
      epicTokenEndpoint,
      epicFhirEndpoint,
      epicAudienceEndpoint,
      epicAuthorizeEndpoint,
      epicMetadataEndpoint,
      msg,
      isshowMsg,
    } = this.state;

    return (
      <div>
        {this.renderRedirect()}
        <div>
          <Form
            name="frmEditFacilityCredentials"
            className="frmEditFacilityCredentials needs-validation"
            id="frmEditFacilityCredentials"
            onSubmit={this.handleEpicSubmit}
            noValidate
          >
            <Row form>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label for="epicClientId">
                    Epic Client ID <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={epicClientId}
                    name="epicClientId"
                    onChange={this.changeHandler}
                    type="text"
                    id="epicClientId"
                    placeholder="Epic Client ID"
                    required
                  />
                  {this.validator.message(
                    "epicClientId",
                    epicClientId,
                    "required",
                    {
                      messages: {
                        required: "Please enter a valid epic client id.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label for="epicClientSecret">Epic Client Secret</Label>
                  <Input
                    value={epicClientSecret}
                    name="epicClientSecret"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicClientSecret"
                    placeholder="Epic Client Secret"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="epicAudienceEndpoint">
                    Epic Audience Endpoint
                    <span className="rqfield"> *</span>
                  </Label>
                  <Input
                    value={epicAudienceEndpoint}
                    name="epicAudienceEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicAudienceEndpoint"
                    placeholder="Epic Audience Endpoint"
                    required
                  />
                  {this.validator.message(
                    "epicAudienceEndpoint",
                    epicAudienceEndpoint,
                    "required|url",
                    {
                      messages: {
                        required:
                          "Please enter a valid epic audience  endpoint.",
                        url: "Please enter a valid epic audience  endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="epicFhirEndpoint">
                    Epic FHIR Endpoint
                    <span className="rqfield"> *</span>
                  </Label>
                  <Input
                    value={epicFhirEndpoint}
                    name="epicFhirEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicFhirEndpoint"
                    placeholder="Epic FHIR Endpoint"
                    required
                  />
                  {this.validator.message(
                    "epicFhirEndpoint",
                    epicFhirEndpoint,
                    "required|url",
                    {
                      messages: {
                        required: "Please enter a valid epic fhir  endpoint.",
                        url: "Please enter a valid epic fhir  endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col lg={12}>
                <p className="text-muted">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="1x"
                    className="fa-fw"
                  />{" "}
                  Either Epic Metadata Endpoint or Epic Authorize and Token
                  Endpoints are required.
                </p>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="epicMetadataEndpoint">
                    Epic Metadata Endpoint
                  </Label>
                  <Input
                    value={epicMetadataEndpoint}
                    name="epicMetadataEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicMetadataEndpoint"
                    placeholder="Epic Metadata Endpoint"
                  />
                  {isshowMsg && (
                    <div className={"text-danger font-weight-bold"}>
                      <FontAwesomeIcon icon={faExclamationTriangle} size="1x" />{" "}
                      Please enter a valid epic metadata endpoint
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <Button
                  className="mb-2 mr-2 btn-square btn-gradient-success btn-wrap"
                  // onClick={this.handlefetchDetails(() => "Epic")}
                  onClick={() => this.handlefetchDetails("Epic")}
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="fa-fw" />{" "}
                  Fetch FHIR Epic Server Endpoints
                </Button>
              </Col>
            </Row>
            <Row form>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="epicAuthorizeEndpoint">
                    Epic Authorization Endpoint{" "}
                    <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={epicAuthorizeEndpoint}
                    name="epicAuthorizeEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicAuthorizeEndpoint"
                    placeholder="Epic Authorization Endpoint"
                    required
                  />
                  {this.validator.message(
                    "epicAuthorizeEndpoint",
                    epicAuthorizeEndpoint,
                    "required|url",
                    {
                      messages: {
                        required:
                          "Please enter a valid epic authorization endpoint.",
                        url: "Please enter a valid epic authorization endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="epicTokenEndpoint">
                    Epic Token Endpoint <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={epicTokenEndpoint}
                    name="epicTokenEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="epicTokenEndpoint"
                    placeholder="Epic Token Endpoint"
                    required
                  />
                  {this.validator.message(
                    "epicTokenEndpoint",
                    epicTokenEndpoint,
                    "required|url",
                    {
                      messages: {
                        required: "Please enter a valid epic token endpoint.",
                        url: "Please enter a valid epic token endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col size="12" lg="12" className="mt-4">
                <FormGroup check row>
                  <Col sm={12} className="text-right">
                    <Button className="mb-2 mr-2 btn-square btn-gradient-success">
                      <FontAwesomeIcon icon={faPlusCircle} className="fa-fw" />{" "}
                      Submit
                    </Button>
                  </Col>
                </FormGroup>
                <AlertComponent msg={this.state.epicMsg} />
              </Col>
            </Row>
          </Form>
        </div>
        <AlertComponent msg={msg} />
      </div>
    );
  }
}

export default withParams(EpicFacilityCredentials);
