import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import NavOverlay from "./components/NavOverlay";
import NavDivider from "./components/NavDivider";
import NavSingleItem from "./components/NavSingleItem";
import NavDropdownItem from "./components/NavDropdownItem";
import PageAlertContext from "../PageAlert/PageAlertContext";

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const navItems = (items) =>
      items.map((item, index) => itemType(item, index));

    const itemType = (item, index) => {
      if (item.children) {
        return (
          <NavDropdownItem
            key={index}
            item={item}
            isSidebarCollapsed={this.props.isSidebarCollapsed}
          />
        );
      }
      if (item.divider) {
        return <NavDivider key={index} />;
      }
      return <NavSingleItem navitem={item} key={index} />;
    };

    const NavBrand = ({ logo, logoText }) => (
      <div className="site-logo-bar">
        <NavLink to="/admin/home" className="navbar-brand">
          {logo &&<div><img src={logo} alt="" className="nav-img" /></div> }
          {logoText && <span className="logo-text">{logoText}</span>}
        </NavLink>
      </div>
    );

    return (
      <PageAlertContext.Consumer>
        {(consumer) => {
          const hasPageAlertClass = consumer.alert ? "has-alert" : "";
          return (
            <div>
              <div className={`app-sidebar ${hasPageAlertClass}`}>
               <NavBrand
                  logo={this.props.logo}
                  logoText={this.props.logoText}
                />
                <nav>
                  <ul id="main-menu">{navItems(this.props.nav.top)}</ul>
                </nav>
              </div>
              {this.props.isSidebarCollapsed && (
                <NavOverlay onClick={this.props.toggleSidebar} />
              )}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
    );
  }
}
