/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Header,
  SidebarNav,
  Footer,
  PageContent,
  PageAlert,
  Page,
} from "../../../../styles";
import nav from "./nav";
import ContextProviders from "../../../../styles/components/utilities/ContextProviders";
import handleKeyAccessibility, {
  handleClickAccessibility,
} from "../../../../styles/helpers/handleTabAccessibility";
import Dashboard from "../Dashboard/home";
import Facilities from "../Facility/index";
import AddFacility from "../Facility/add";
import EditFacility from "../Facility/edit";
import BillingSystem from "../BillingSystem";
import EditFacilityCredentials from "../Facility/editCredentials";
import AuditLog from "../Auditlog";
import { browserStorageHelper } from "../../../../helpers";
import HeaderNav from "../Logout";
import { userTypeConfig, appConfigs } from "../../../../config";
import AddBillingSystem from "../BillingSystem/add";
import EditBillingSystem from "../BillingSystem/edit";

const MOBILE_SIZE = 992;

export default class DashboardLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("keydown", handleKeyAccessibility);
    document.addEventListener("click", handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  toggleSideCollapse = () => {
    this.setState((prevState) => ({
      sidebarCollapsed: !prevState.sidebarCollapsed,
    }));
  };

  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? "side-menu-collapsed" : "";

    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <SidebarNav
              nav={nav}
              logoText="Listen MD"
              // logo={logoLight}
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                {...this.props}
              >
                <HeaderNav />
              </Header>
              <PageContent>
                {browserStorageHelper.getItem("uid") ===
                userTypeConfig.Admin ? (
                  <Routes>
                    <Route path="/home" element={<Dashboard />} />
                    <Route path="/facilities" element={<Facilities />} />
                    <Route path="/add-facility" element={<AddFacility />} />
                    <Route
                      path="/edit-facility/:facilityId"
                      element={<EditFacility />}
                    />
                    <Route
                      path="/edit-facility-credentials/:facilityId"
                      element={<EditFacilityCredentials />}
                    />
                    <Route
                      path="/billingsystem"
                      element={<BillingSystem />}
                    />
                     <Route path="/add-billing-system" element={<AddBillingSystem />} />
                     <Route
                      path="/edit-billing-system/:billingSytemId"
                      element={<EditBillingSystem />}
                    />
                    <Route path="/auditlogs" element={<AuditLog />} />
                  </Routes>
                ) : (
                  <Navigate to="/admin/login" />
                )}
              </PageContent>
            </Page>
          </div>
          <Footer>
            <span>
              Copyright <FontAwesomeIcon icon={faCopyright} size="1x" />{" "}
              {new Date().getFullYear()}{" "}
              <a href={appConfigs.COMPANY_URL} target="_blank" rel="noreferrer">
                {appConfigs.COMPANY_NAME}
              </a>
            </span>
          </Footer>
        </div>
      </ContextProviders>
    );
  }
}
