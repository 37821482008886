/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import { Row, Col, Button, CardHeader, Card, CardBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faRedo,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../../config";
import {
  browserStorageHelper,
  commonHelpers,
  encryptionHelper,
} from "../../../../../helpers";
import Accordion from "../../CommonComponents/Accordion";
import EpicCredentials from "./epicCredentials";
import CernerCredentials from "./cernerCredentials";

/* eslint-disable-next-line react/jsx-props-no-spreading */
function withParams(Component) {
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

class EditFacilityCredentials extends PureComponent {
  constructor() {
    super();
    this.state = {
      responseType: "",
      redirectUrl: "/admin/facilities",
      facilityEhrType: "",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  componentDidMount = async () => {
    const self = this;

    const { facilityId } = this.props.params;
    if (typeof facilityId === "undefined") {
      const redirect = true;
      const redirectUrl = self.state.redirectUrl;
      self.setState({ redirect, redirectUrl });
    }

    const token = browserStorageHelper.getItem("token");
    const uid = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/credentials?user_id=${uid}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        let facilityDetails = res.data.result.facilityDetails;
        self.setState({
          msg: commonHelpers.setMsg("", "", "", ""),
        });
        if (facilityDetails !== null) {
          self.setState({
            facilityEhrType: facilityDetails.facility_ehr_type,
          });
        }
      })
      .catch((err) => {
        self.setState({
          msg: commonHelpers.errMessage(err),
        });
        window.location.href = `/admin/facilities?error=${encryptionHelper.encrypt(
          self.state.msg.msgError
        )}`;
      });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const { facilityEhrType } = this.state;
    return (
      <div>
        {this.renderRedirect()}

        <div className="container-fluid mt-3">
          <Row className="py-3">
            <Col md="12">
              <Card>
                <CardHeader className="card-header-tab z-index-6">
                  <Row>
                    <Col size="12" lg="9" className="mb-2 font-weight-bold">
                      Edit Facility Credentials
                    </Col>
                    <Col size="12" lg="3" className="mb-2">
                      <Link to="/admin/facilities">
                        <Button
                          className="mb-2 mr-2 btn-square btn-gradient-success backBtn"
                          style={{ float: "right" }}
                        >
                          <FontAwesomeIcon icon={faRedo} className="fa-fw" />{" "}
                          Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {facilityEhrType === "Epic" && (
                    <div label="Epic Credentials" id="Epic">
                      <EpicCredentials />
                    </div>
                  )}
                  {facilityEhrType === "Cerner" && (
                    <div label="Cerner Credentials" id="Cerner">
                      <CernerCredentials />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withParams(EditFacilityCredentials);
