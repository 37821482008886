/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faPlusCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../../config";
import {
  browserStorageHelper,
  commonHelpers,
  encryptionHelper,
} from "../../../../../helpers";
import { AlertComponent } from "../../../../../components";

/* eslint-disable-next-line react/jsx-props-no-spreading */
function withParams(Component) {
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

class CernerFacilityCredentials extends PureComponent {
  constructor() {
    super();
    this.state = {
      facilityId: "",
      ehrType: "",
      epicClientId: "",
      epicClientSecret: "",
      epicAuthorizeEndpoint: "",
      epicTokenEndpoint: "",
      epicFhirEndpoint: "",
      epicAudienceEndpoint: "",
      epicMetadataEndpoint: "",
      cernerClientId: "",
      cernerClientSecret: "",
      cernerAuthorizeEndpoint: "",
      cernerTokenEndpoint: "",
      cernerFhirEndpoint: "",
      cernerAudienceEndpoint: "",
      cernerMetadataEndpoint: "",
      athenaSystemAppClientId: "",
      athenaSystemAppClientSecret: "",
      athenaAuthorizeEndpoint: "",
      athenaTokenEndpoint: "",
      responseType: "",
      isshowcernerMsg: false,
      redirectUrl: "/admin/facilities",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },

      cernerMsg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  componentDidMount = async () => {
    const self = this;

    const { facilityId } = this.props.params;
    if (typeof facilityId === "undefined") {
      const redirect = true;
      const redirectUrl = self.state.redirectUrl;
      self.setState({ redirect, redirectUrl });
    }
    self.setState({
      facilityId,
      msg: commonHelpers.setMsg("Fetching data...", "", "", ""),
    });

    const token = browserStorageHelper.getItem("token");
    const uid = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/credentials?user_id=${uid}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        let facilityCredentials = res.data.result.facilityCredentials;
        self.setState({
          msg: commonHelpers.setMsg("", "", "", ""),
        });
        if (facilityCredentials !== null) {
          self.setState({
            cernerClientId:
              facilityCredentials.cerner_client_id === null
                ? ""
                : facilityCredentials.cerner_client_id,
            cernerClientSecret:
              facilityCredentials.cerner_client_secret === null
                ? ""
                : facilityCredentials.cerner_client_secret,
            cernerAuthorizeEndpoint:
              facilityCredentials.cerner_authorize_endpoint === null
                ? ""
                : facilityCredentials.cerner_authorize_endpoint,
            cernerTokenEndpoint:
              facilityCredentials.cerner_token_endpoint === null
                ? ""
                : facilityCredentials.cerner_token_endpoint,
            cernerFhirEndpoint:
              facilityCredentials.cerner_fhir_endpoint === null
                ? ""
                : facilityCredentials.cerner_fhir_endpoint,
            cernerAudienceEndpoint:
              facilityCredentials.cerner_audience_endpoint === null
                ? ""
                : facilityCredentials.cerner_audience_endpoint,
            cernerMetadataEndpoint:
              facilityCredentials.cerner_metadata_endpoint === null
                ? ""
                : facilityCredentials.cerner_metadata_endpoint,
          });
        }
        self.setState({
          msg: commonHelpers.setMsg("", "", "", ""),
        });
      })
      .catch((err) => {
        self.setState({
          msg: commonHelpers.errMessage(err),
        });
        window.location.href = `/admin/facilities?error=${encryptionHelper.encrypt(
          self.state.msg.msgError
        )}`;
      });
  };

  handleCernerSubmit = async (event) => {
    event.preventDefault();
    const self = this;
    self.setState({
      isshowcernerMsg: false,
    });
    if (this.validator.allValid()) {
      self.setState({
        cernerMsg: commonHelpers.setMsg(
          "Updating cerner facility credentials...",
          "",
          "",
          ""
        ),
      });
      const token = browserStorageHelper.getItem("token");
      const userId = browserStorageHelper.getItem("uid");
      const requestToken = commonHelpers.generateRequestToken();
      const {
        facilityId,
        cernerClientId,
        cernerClientSecret,
        cernerAuthorizeEndpoint,
        cernerTokenEndpoint,
        cernerFhirEndpoint,
        cernerAudienceEndpoint,
        cernerMetadataEndpoint,
      } = this.state;
      const postData = {
        user_id: userId,
        cerner_client_id: cernerClientId,
        cerner_client_secret: cernerClientSecret,
        cerner_authorize_endpoint: cernerAuthorizeEndpoint,
        cerner_token_endpoint: cernerTokenEndpoint,
        cerner_fhir_endpoint: cernerFhirEndpoint,
        cerner_audience_endpoint: cernerAudienceEndpoint,
        cerner_metadata_endpoint: cernerMetadataEndpoint,
        request_token: requestToken,
      };
      const axiosOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/cerner/credentials`,
          postData,
          axiosOptions
        )
        .then((res) => {
          self.setState({
            cernerMsg: commonHelpers.setMsg(
              "",
              "",
              "Cerner Facility credentials updated successfully.",
              ""
            ),
          });
          const redirect = true;
          const redirectUrl = self.state.redirectUrl;
          self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
          self.setState({
            cernerMsg: commonHelpers.errMessage(err),
          });
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  handlefetchDetails = async (event) => {
    const self = this;

    const token = browserStorageHelper.getItem("token");
    const userId = browserStorageHelper.getItem("uid");
    const requestToken = commonHelpers.generateRequestToken();
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { cernerMetadataEndpoint, cernerClientId, responseType } = self.state;
    self.setState({
      isshowcernerMsg: false,
    });
    var cilentId, metaDataEndpoint;

    if (event === "Cerner") {
      if (cernerMetadataEndpoint === "") {
        return self.setState({
          isshowcernerMsg: true,
        });
      }
      self.setState({
        cernerMsg: commonHelpers.setMsg(
          "Fetching FHIR Cerner Server Endpoints...",
          "",
          "",
          ""
        ),
      });
      cilentId = cernerClientId;
      metaDataEndpoint = cernerMetadataEndpoint;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/metadata?user_id=${userId}&metadata_endpoint=${metaDataEndpoint}&response_type=${responseType}&client_id=${cilentId}&request_token=${requestToken}`,
        axiosOptions
      )
      .then((res) => {
        const ehr_authorize_endpoint = res.data.result.authorizeEndpoint;
        const ehr_token_endpoint = res.data.result.tokenEndpoint;
        if (ehr_authorize_endpoint === null && ehr_token_endpoint === null) {
          if (event === "Cerner") {
            return self.setState({
              cernerMsg: commonHelpers.setMsg(
                "",
                "Error: Unable to fetch cerner metadata details.",
                "",
                ""
              ),
            });
          }
        }

        if (event === "Cerner") {
          self.setState({
            cernerAuthorizeEndpoint: ehr_authorize_endpoint,
            cernerTokenEndpoint: ehr_token_endpoint,
          });
          self.setState({
            cernerMsg: commonHelpers.setMsg(
              "",
              "",
              "Cerner metadata details fetched successfully.",
              ""
            ),
          });
        }
      })
      .catch((err) => {
        if (event === "Cerner") {
          self.setState({
            cernerMsg: commonHelpers.errMessage(err),
          });

          self.setState({
            cernerAuthorizeEndpoint: "",
            cernerTokenEndpoint: "",
          });
        }
      });
  };

  render() {
    const {
      cernerClientId,
      cernerClientSecret,
      cernerTokenEndpoint,
      cernerFhirEndpoint,
      cernerAudienceEndpoint,
      cernerAuthorizeEndpoint,
      cernerMetadataEndpoint,
      msg,
      cernerMsg,
      isshowcernerMsg,
    } = this.state;

    return (
      <div>
        {this.renderRedirect()}

        <div>
          <Form
            name="frmEditFacilityCredentials"
            className="frmEditFacilityCredentials needs-validation"
            id="frmEditFacilityCredentials"
            // onClick={this.handlechange("Cerner")}
            onSubmit={this.handleCernerSubmit}
            noValidate
          >
            <Row form>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label for="cernerClientId">
                    Cerner Client ID <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={cernerClientId}
                    name="cernerClientId"
                    onChange={this.changeHandler}
                    type="text"
                    id="cernerClientId"
                    placeholder="Cerner Client ID"
                    required
                  />
                  {this.validator.message(
                    "cernerClientId",
                    cernerClientId,
                    "required",
                    {
                      messages: {
                        required: "Please enter a valid cerner client id.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup>
                  <Label for="cernerClientSecret">
                    Cerner Client Secret <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={cernerClientSecret}
                    name="cernerClientSecret"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerClientSecret"
                    placeholder="Cerner Client Secret"
                  />
                  {this.validator.message(
                    "cernerClientSecret",
                    cernerClientSecret,
                    "required",
                    {
                      messages: {
                        required: "Please enter a valid cerner client secret.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="cernerAudienceEndpoint">
                    Cerner Audience Endpoint
                  </Label>
                  <Input
                    value={cernerAudienceEndpoint}
                    name="cernerAudienceEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerAudienceEndpoint"
                    placeholder="Cerner Audience Endpoint"
                  />
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="cernerFhirEndpoint">
                    Cerner FHIR Endpoint
                    <span className="rqfield"> *</span>
                  </Label>
                  <Input
                    value={cernerFhirEndpoint}
                    name="cernerFhirEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerFhirEndpoint"
                    placeholder="Cerner FHIR Endpoint"
                    required
                  />
                  {this.validator.message(
                    "cernerFhirEndpoint",
                    cernerFhirEndpoint,
                    "required|url",
                    {
                      messages: {
                        required: "Please enter a valid cerner fhir  endpoint.",
                        url: "Please enter a valid cerner fhir  endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col lg={12}>
                <p className="text-muted">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="1x"
                    className="fa-fw"
                  />{" "}
                  Either Cerner Metadata Endpoint or Cerner Authorize and Token
                  Endpoints are required.
                </p>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="cernerMetadataEndpoint">
                    Client Metadata Endpoint
                  </Label>
                  <Input
                    value={cernerMetadataEndpoint}
                    name="cernerMetadataEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerMetadataEndpoint"
                    placeholder="Cerner Metadata Endpoint"
                  />
                  {isshowcernerMsg && (
                    <div className={"text-danger font-weight-bold"}>
                      <FontAwesomeIcon icon={faExclamationTriangle} size="1x" />{" "}
                      Please enter a valid cerner metadata endpoint
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <Button
                  className="mb-2 mr-2 btn-square btn-gradient-success btn-wrap"
                  onClick={() => this.handlefetchDetails("Cerner")}
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="fa-fw" />{" "}
                  Fetch FHIR Cerner Server Endpoints
                </Button>
              </Col>
            </Row>
            <Row form>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="cernerAuthorizeEndpoint">
                    Cerner Authorization Endpoint{" "}
                    <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={cernerAuthorizeEndpoint}
                    name="cernerAuthorizeEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerAuthorizeEndpoint"
                    placeholder="Cerner Authorization Endpoint"
                    required
                  />
                  {this.validator.message(
                    "cernerAuthorizeEndpoint",
                    cernerAuthorizeEndpoint,
                    "required|url",
                    {
                      messages: {
                        required:
                          "Please enter a valid cerner authorization endpoint.",
                        url: "Please enter a valid cerner authorization endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
              <Col lg={4} md={12} sm={12}>
                <FormGroup>
                  <Label for="cernerTokenEndpoint">
                    Cerner Token Endpoint <span className="rqfield">*</span>
                  </Label>
                  <Input
                    value={cernerTokenEndpoint}
                    name="cernerTokenEndpoint"
                    onChange={this.changeHandler}
                    type="textarea"
                    id="cernerTokenEndpoint"
                    placeholder="Cerner Token Endpoint"
                    required
                  />
                  {this.validator.message(
                    "cernerTokenEndpoint",
                    cernerTokenEndpoint,
                    "required|url",
                    {
                      messages: {
                        required: "Please enter a valid cerner token endpoint.",
                        url: "Please enter a valid cerner token endpoint.",
                      },
                    }
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col size="12" lg="12" className="mt-4">
                <FormGroup check row>
                  <Col sm={12} className="text-right">
                    <Button className="mb-2 mr-2 btn-square btn-gradient-success">
                      <FontAwesomeIcon icon={faPlusCircle} className="fa-fw" />{" "}
                      Submit
                    </Button>
                  </Col>
                </FormGroup>
                <AlertComponent msg={cernerMsg} />
              </Col>
            </Row>
          </Form>
        </div>

        <AlertComponent msg={msg} />
      </div>
    );
  }
}

export default withParams(CernerFacilityCredentials);
