// eslint-disable-next-line
/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Navigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../../styles/scss/components/loginPage.scss";
import {
  browserStorageHelper,
  commonHelpers,
  encryptionHelper,
} from "../../../../helpers";
import { AlertComponent } from "../../../../components";
import { appConfigs, axios } from "../../../../config";

class Login extends PureComponent {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      redirect: false,
      redirectUrl: "/admin/home",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
    });
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  componentDidMount = async () => {
    let self = this;

    self.setState({
      msg: commonHelpers.setMsg("Checking for browser support...", "", "", ""),
    });
    let supported = await browserStorageHelper.checkSupport();
    if (supported != null) {
      self.setState({
        msg: commonHelpers.setMsg("", supported, "", ""),
      });
    }
    if (supported === null) {
      self.setState({
        msg: commonHelpers.setMsg("", "", "", ""),
      });
    }

    const error = window.location.search.split("?error=")[1];
    if (error != undefined && error != "" && error != null) {
      var decryptedData = encryptionHelper.decrypt(error);
      if (
        decryptedData != undefined &&
        decryptedData != "" &&
        decryptedData != null
      ) {
        await self.setState({
          msg: commonHelpers.setMsg("", decryptedData, "", ""),
        });
      }
    }
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    const self = this;
    e.preventDefault();
    self.setState({
      msg: commonHelpers.setMsg("Logging in...", "", "", ""),
    });
    const requestToken = commonHelpers.generateRequestToken();
    if (this.validator.allValid()) {
      const loginData = {
        username: self.state.username,
        password: self.state.password,
        request_token: requestToken,
      };
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/admin/login`, loginData)
        .then((response) => {
          const msgArr = commonHelpers.setMsg("", "", "Login successful.", "");
          self.setState({
            msg: msgArr,
          });
          const resultData = response.data.result;
          const uToken = resultData.token;
          const uId = resultData.uid;
          const redirect = true;
          const redirectUrl = this.state.redirectUrl;
          browserStorageHelper.setItem("token", uToken);
          browserStorageHelper.setItem("uid", uId);
          self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
          const msgErr = commonHelpers.errMessage(err);
          self.setState({
            msg: msgErr,
          });
        });
    } else {
      self.setState({
        msg: self.msgBlank,
      });
      self.validator.showMessages();
    }
  }

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const { username, password, msg } = this.state;

    return (
      <div className="App">
        {this.renderRedirect()}
        <div className="outer">
          <div className="inner">
        
            <div className="">
              <Form onSubmit={this.onSubmit}>
                <h3>Log in</h3>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    placeholder="Username"
                    name="username"
                    id="username"
                    value={username}
                    onChange={this.onChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.onChange}
                    required
                  />
                </FormGroup>
                <Button
                  className="btn btn-dark btn-lg btn-block"
                  color="primary"
                  size="lg"
                  type="submit"
                >
                  Login
                </Button>
              </Form>
              <AlertComponent msg={msg} />
            </div>
          </div>
          <br />
          <div className="text-center">
            <span>
              Copyright <FontAwesomeIcon icon={faCopyright} size="1x" />{" "}
              {new Date().getFullYear()}{" "}
              <a href={appConfigs.COMPANY_URL} target="_blank" rel="noreferrer">
                {appConfigs.COMPANY_NAME}
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
